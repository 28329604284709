<template>
  <div class="container" fluid="lg">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 text-center">
            <div class="p-5">
              <b-img :src="sypitarn_mascot" class="mx-auto" style="max-width: 15rem" alt="Mascot"></b-img>
            </div>
            <h5>{{ $t('your_loan_is_rejected') }}</h5>
            <h6 class="pt-4" v-html="rejectionMessage"></h6>
            <router-link to="/faq">{{ $t('faqs') }}</router-link>
          </div>
          <div class="col-12 col-md-6 text-center p-2">
            <router-link to="/">
              <b-button pill variant="primary" class="w-100">
                {{ $t('go_to_landing_page') }}
              </b-button>
            </router-link>
          </div>
          <div class="col-12 col-md-6 text-center p-2">
            <b-button pill variant="outline-primary" class="w-100" @click="logout()">
              {{ $t('logout') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RejectionService from '@/api/service/RejectionService'
export default {
  data() {
    return {
      sypitarn_mascot: require('@/assets/images/sypitarn_mascot.png'),
      rejectionMessage: '',
    }
  },
  created() {
    this.getRejectionStatus()
  },
  methods: {
    async getRejectionStatus() {
      try {
        const rejectionResponse = await RejectionService.getRejectionStatus({
          reject_code: this.$store.getters['auth/rejectCode'],
        })
        const rejection = rejectionResponse.data.data
        this.rejectionMessage = rejection.message
      } catch (err) {
        this.showAlert(err)
      }
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$store.dispatch('register/reset')
      this.$router.push({ name: 'landing' })
    },
  },
}
</script>

<style></style>
